export default Object.freeze({
  // RESOURCES
  ACCOUNT: 'account',
  ADMINISTRATION: 'administration',
  ALL_ORGANIZATIONS: 'all_organizations',
  ALL_USERS: 'all_users',
  ALL_WEBSITES: 'all_websites',
  ALL_WEBSITE_PRODUCTS: 'all_website_products',
  API_TOKEN: 'api_token',
  COUNTRY: 'country',
  DEFAULT_ADSTXT: 'default_adstxt',
  DEFAULT_BIDDERS: 'default_bidders',
  GENDER: 'gender',
  MANAGEMENT: 'management',
  ORGANIZATION: 'organization',
  ORGANIZATION_EXCLUSION: 'organization_exclusion',
  ORGANIZATION_INVITATION: 'organization_invitation',
  ORGANIZATION_TYPE: 'organization_type',
  ROLE: 'role',
  USER: 'user',
  USER_GROUP: 'user_group',
  USER_HAS_ORGANIZATIONS: 'user_has_organizations',
  USER_HAS_WEBSITES: 'user_has_websites',
  VALIDATION_EMAIL: 'validation_email',
  WEBSITE: 'website',
  WEBSITE_ADSTXT_MONITORING: 'website_adstxt_monitoring',
  WEBSITE_BIDDERS: 'website_bidders',
  WEBSITE_CONFIG: 'website_config',
  WEBSITE_CONFIG_FORCE_UNLOCK: 'website_config_force_unlock',
  WEBSITE_CONFIG_LOCK: 'website_config_lock',
  WORDPRESS_TOKEN_DEBUG: 'wordpress_token_debug',
  // FIELDS
  FIELD_ACTIVE: 'active',
  FIELD_ADS_TXT: 'adstxt',
  FIELD_ALLOWED_USERS: 'allowed_users',
  FIELD_BIDDERS: 'bidders',
  FIELD_DIFF: 'diff',
  FIELD_FEES: 'fees',
  FIELD_NOTE: 'note',
  FIELD_PLAINPASSWORD: 'plainPassword',
  FIELD_PRESIZED_DIVS: 'presized_divs',
  FIELD_PUBLISH: 'publish',
  FIELD_SELLERS_JSON_ORGANIZATION: 'sellers_json_organization',
  FIELD_SERVICE_STATUS: 'service_status',
  FIELD_TAGGING: 'tagging',
  FIELD_UNLOCK: 'unlock',
  FIELD_UUID: 'uuid',
  FIELD_VALIDATED: 'validated'
})
