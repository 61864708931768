import views from '@enums/views'
import config from '@enums/config'

const TheLogin = () =>
  import(/* webpackChunkName: "the-Login" */ '@views/TheLogin.vue')

export default [
  {
    path: `/${views.LOGIN}`,
    name: views.LOGIN,
    component: TheLogin,
    meta: {
      layout: config.LAYOUT_FULL,
      publicOnly: true
    }
  }
]
