import {
  stateTable, gettersTable, mutationsTable, actionsTable
} from '@store/modules/table'
import configStatus from '@enums/configStatus'

/**
 * @typedef {Object} WebsiteConfigs
 * @prop {string} context - as @context
 * @prop {string} id - as @id
 * @prop {string} type - as @type
 * @prop {array} hydraMember - as hydra:member
 * @prop {string} hydraMember.id - as hydra:member.@id
 * @prop {string} hydraMember.website - as hydra:member.website
 * @prop {number} hydraMember.version - as hydra:member.version
 * @prop {string} hydraMember.status - as hydra:member.status
 * @prop {object} hydraMember.json - as hydra:member.json
 * @prop {string} hydraMember.createdBy - as hydra:member.createdBy
 * @prop {string} hydraMember.createdAt - as hydra:member.createdAt
 * @prop {string} hydraMember.note - as hydra:member.note
 * @prop {number} hydraTotalItems - as hydra:totalItems
 * @prop {object} hydraSearch - as hydra:search
 */

/**
 * @typedef {Object} WebsiteConfig
 * @prop {string} website
 * @prop {number} version
 * @prop {string} status
 * @prop {object} json
 * @prop {string} createdBy
 * @prop {string} createdAt
 * @prop {string} note
 */

/** @typedef {import('./modules/table').TableFiltersState} TableFiltersState */

/**
 * @typedef {Object} State
 * @prop {object} service
 * @prop {WebsiteConfig[]} [items]
 * @prop {number} totalItems
 * @prop {TableFiltersState} tableFiltersState
 * @prop {{order: {version:desc}}} [tableFiltersState.order.version=desc]
 * @prop {string} editedItemId
 */

/** @type {State} */
export const state = {
  service: {
    name: 'websiteConfigsService',
    getItemsMethod: 'getWebsiteConfigs'
  },
  ...stateTable,
  editedItemId: null,
  showArchived: false,
  hasDraft: false
}

export const getters = {
  ...gettersTable
}

export const mutations = {
  ...mutationsTable,
  /**
   * @param {State} state
   * @param {string, number} id
   */
  setEditedItemId (state, id) {
    state.editedItemId = id
  },
  /**
   * @param {State} state
   * @param {boolean} archived
   */
  setShowArchived (state, archived) {
    state.showArchived = archived
  },
  /**
   * @param {State} state
   * @param {boolean} hasDraft
   */
  setHasDraft (state, hasDraft) {
    state.hasDraft = hasDraft
  }
}

export const actions = {
  ...actionsTable,
  /**
   * Website config has draft or pending ?
   * @param {ActionContext} context
   * @param {string} itemIRI
   * @returns {AxiosPromise}
   */
  async fetchWebsiteConfigsHasDraft ({ commit }, itemIRI) {
    try {
      const { data } = await this.$services.websiteConfigsService.getWebsiteConfigs({
        website: itemIRI,
        status: [configStatus.DRAFT, configStatus.PENDING]
      })
      // Keep data in store
      commit('setHasDraft', data?.['hydra:member'].length > 0)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
