import actions from '@enums/actions'
import adsTxt from '@enums/adsTxt'
import analytics from '@enums/analytics'
import analyticsMetrics from '@enums/analyticsMetrics'
import apiTokenStatus from '@enums/apiTokenStatus'
import bidderStatus from '@enums/bidderStatus'
import brandLogos from '@enums/brandLogos'
import browsers from '@enums/browsers'
import config from '@enums/config'
import configStatus from '@enums/configStatus'
import cornerTemplate from '@enums/cornerTemplate'
import devices from '@enums/devices'
import devicesIcons from '@enums/devicesIcons'
import endpoints from '@enums/endpoints'
import feedTemplate from '@enums/feedTemplate'
import hierarchy from '@enums/hierarchy'
import icons from '@enums/icons'
import organizationTypeValues from '@enums/organizationTypeValues'
import overview from '@enums/overview'
import placeholder from '@enums/placeholder'
import scss from '@enums/scss'
import slotTemplates from '@enums/slotTemplates'
import statusCode from '@enums/statusCode'
import subjects from '@enums/subjects'
import table from '@enums/table'
import views from '@enums/views'
import websiteProducts from '@enums/websiteProducts'

export default Object.freeze({
  actions,
  adsTxt,
  analytics,
  analyticsMetrics,
  apiTokenStatus,
  bidderStatus,
  brandLogos,
  browsers,
  config,
  configStatus,
  cornerTemplate,
  devices,
  devicesIcons,
  endpoints,
  feedTemplate,
  hierarchy,
  icons,
  organizationTypeValues,
  overview,
  placeholder,
  scss,
  slotTemplates,
  statusCode,
  subjects,
  table,
  views,
  websiteProducts
})
