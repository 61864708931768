import { useAbility } from '@caslAbility'
import isObject from '@utils/isObject'

/**
 * Verify if resource is accessible
 * @param {Object} to
 * @returns {boolean}
 */
export const canNavigate = to => {
  // The route is not protected, we continue
  if (!to.meta?.action && !to.meta?.subject) return true

  const ability = useAbility()

  // Multiples actions with single subject
  if (isObject(to.meta?.action)) {
    const can = []
    to.meta.action.forEach(action => {
      can.push(ability.can(action, to.meta?.subject, to.meta?.field))
    })

    return can.every(value => value)
  }

  // Multiples subjects with single action
  if (isObject(to.meta?.subject)) {
    return to.meta?.subject.some(s => ability.can(to.meta?.action, s, to.meta?.field))
  }

  // Single action
  return ability.can(to.meta?.action, to.meta?.subject, to.meta?.field)
}

export const _ = null
