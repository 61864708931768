import bidders from '@fixtures/bidders-website-config'
import biddersWebsiteConfigTemplates from '@fixtures/bidders-website-config-templates.json'
import biddersSsp from '@fixtures/bidders-ssp-website-config'
import biddersSspWebsiteConfigTemplates from '@fixtures/bidders-ssp-website-config-templates.json'

export const state = {
  items: bidders,
  itemsSsp: biddersSsp,
  biddersTemplates: biddersWebsiteConfigTemplates,
  biddersSspTemplates: biddersSspWebsiteConfigTemplates
}

export const getters = {
  /**
   * Return the bidder associated with a bidder
   * @param {State} state
   * @param {Object} bidder
   * @param {boolean} [flashbidProduct=true]
   * @returns {Function}
   */
  getBidder: state => (bidder, flashbidProduct = true) => {
    const targetBidder = flashbidProduct ? state.items : state.itemsSsp
    return targetBidder.find(({ id, label }) => id === bidder.id || label === bidder.name)
  },
  /**
   * Return the bidder template associated with a bidder
   * @param {Object} bidder
   * @param {boolean} [flashbidProduct=true]
   * @returns {Function}
   */
  getBidderTemplate: () => (bidder, flashbidProduct = true) => {
    const targetTemplates = flashbidProduct ? biddersWebsiteConfigTemplates : biddersSspWebsiteConfigTemplates
    return targetTemplates.find(({ id }) => id === bidder.target)
  }
}

export const mutations = {}

export const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
