import displayIdByIri from '@utils/displayIdByIri'
import isObject from '@utils/isObject'
import table from '@enums/table'

/**
 * @typedef {Object} TableFiltersState - Table state submitted by the accounts table
 * @prop {number} [itemsPerPage]
 * @prop {number} [page] - current page
 * @prop {Object.<string, "asc" | "desc">} [order] - order[key]=value
 */

/**
 * @typedef {Object} State
 * @prop {Array|undefined} [items]
 * @prop {number} totalItems
 * @prop {TableFiltersState} tableFiltersState
 * @prop {Object} tableColumnFilters
 * @prop {Object|undefined} editedItem
 */

/** @type {State} */
export const stateTable = {
  items: undefined,
  totalItems: 0,
  tableFiltersState: {},
  tableColumnFilters: {},
  editedItem: undefined,
  keepIRISerializedData: true
}

export const gettersTable = {
  /**
   * @param {State} state
   */
  allFilters: state => ({
    ...state.tableFiltersState,
    ...state.tableColumnFilters
  }),
  /**
   * @param {State} state
   */
  editedItemName (state) {
    if (!isObject(state.editedItem)) {
      return ''
    }
    return state.editedItem?.name
  },
  /**
   * @param {State} state
   */
  editedItemId (state) {
    if (!isObject(state.editedItem)) {
      return ''
    }

    return displayIdByIri(state.editedItem['@id'])
  }
}

export const mutationsTable = {
  /**
   * @param {State} state
   * @param {Array} [items]
   */
  setItems (state, items) {
    state.items = items
    // Clear pagination if items undefined
    if (items === undefined && state.tableFiltersState?.page) {
      delete state.tableFiltersState[table.CURRENT_PAGE]
    }
  },
  /**
   * @param {State} state
   * @param {number} totalItems
   */
  setTotalItems (state, totalItems) {
    state.totalItems = totalItems
  },
  /**
   * @param {State} state
   * @param {TableFiltersState} tableFiltersState
   */
  setTableFiltersState (state, tableFiltersState) {
    state.tableFiltersState = tableFiltersState
  },
  /**
   * @param {State} state
   * @param {Object} tableColumnFilters
   */
  setTableColumnFilters (state, tableColumnFilters) {
    state.tableColumnFilters = tableColumnFilters
  },
  /**
   * @param {State} state
   * @param {Object} editedItem
   */
  setEditedItem (state, editedItem) {
    // Keep only IRI if serialized data
    if (state.keepIRISerializedData) {
      state.editedItem = Object.keys(editedItem).reduce(
        (acc, key) => ({
          ...acc,
          [key]: isObject(editedItem[key]) && Object.hasOwn(editedItem[key], '@id') ? editedItem[key]['@id'] : editedItem[key]
        }),
        {}
      )
    } else {
      state.editedItem = editedItem
    }
  }
}

/** @typedef {import('vuex').ActionContext<State>} ActionContext - Action context */
/** @typedef {import('axios').AxiosPromise} AxiosPromise */
/** @typedef {TableFiltersState & tableColumnFilters} TableParams */

export const actionsTable = {
  /**
   * Fetch items values
   * @param {ActionContext} context
   * @param {TableParams} params
   * @returns {AxiosPromise}
   */
  async fetchItems ({ state, commit, getters }, params) {
    // Keep column filters state in store
    if ('columnFilters' in params) {
      commit('setTableColumnFilters', { ...params.columnFilters })
      Reflect.deleteProperty(params, 'columnFilters')
    }
    // Keep table filters state in store
    commit('setTableFiltersState', JSON.parse(JSON.stringify(params)))

    try {
      const response = await this.$services[state.service.name][
        state.service.getItemsMethod
      ](getters.allFilters)
      // Keep data in store
      commit('setItems', response?.data?.['hydra:member'] ?? [])
      commit('setTotalItems', response?.data?.['hydra:totalItems'] ?? 0)

      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  /**
   * Fetch item value
   * @param {ActionContext} context
   * @param {string} itemIRI
   * @returns {AxiosPromise}
   */
  async fetchItem ({ state, commit }, itemIRI) {
    const response = await this.$services[state.service.name][
      state.service.getItemMethod
    ](itemIRI)
    // Keep data in store
    commit('setEditedItem', response?.data)

    return response
  },
  /**
   * Send a PATCH request and update item stored in state with the request response
   * @param {ActionContext} actionContext
   * @param {string} itemIRI
   * @param {Object} data
   * @returns {Object}
   */
  async patchItem ({
    commit, state, dispatch, getters
  }, { itemIRI, data }) {
    const response = await this.$services[state.service.name][
      state.service.patchItemMethod
    ](itemIRI, data)
    // Keep data in store
    commit('setEditedItem', response?.data)
    // Update items data stored if this item is in the list
    const storedItems = state.items
    if (storedItems) {
      const isEditedItemStored = storedItems.some(item => item['@id'] === itemIRI)
      if (isEditedItemStored) {
        await dispatch('fetchItems', getters.allFilters)
      }
    }

    return state.editedItem
  }
}
