import Vue from 'vue'
import VueRouter from 'vue-router'
import views from '@enums/views'
import config from '@enums/config'
import store from '@store'
import { i18n, loadLanguageAsync } from '@i18n'
import { setAxiosHeader } from '@axios'
import { canNavigate } from './canNavigate'

// Routes
import notFoundRoutes from './routes/notFoundRoutes'
import loginRoute from './routes/loginRoute'
import profileManagerRoutes from './routes/profileManagerRoutes'
import homePageRoute from './routes/homePageRoute'
import accountsRoutes from './routes/accountsRoutes'
import profileRoutes from './routes/profileRoutes'
import validateRoutes from './routes/validateRoutes'
import organizationsRoutes from './routes/organizationsRoutes'
import websitesRoutes from './routes/websitesRoutes'
import websiteConfigurationRoutes from './routes/websiteConfigurationRoutes'
import biddersRoutes from './routes/biddersRoutes'
import adsTxtRoutes from './routes/adsTxtRoutes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to) {
    return to.hash && document.querySelector(to.hash)
      ? document.querySelector(to.hash).scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
      : {
        x: 0,
        y: 0
      }
  },
  routes: [
    ...notFoundRoutes,
    ...loginRoute,
    ...profileManagerRoutes,
    ...homePageRoute,
    ...accountsRoutes,
    ...profileRoutes,
    ...validateRoutes,
    ...organizationsRoutes,
    ...websitesRoutes,
    ...websiteConfigurationRoutes,
    ...biddersRoutes,
    ...adsTxtRoutes,
    {
      path: '',
      redirect: {
        name: views.HOMEPAGE
      }
    },
    {
      path: '*',
      redirect: {
        name: views.ERROR_404
      }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  // If user is authenticated
  if (store.getters['auth/isAuthenticated']) {
    // And if the route he is trying to access is public-only, we abort navigation
    if (to.meta?.publicOnly) {
      /** If there isn't any previous navigation, we redirect to homepage,
       * otherwise, we abort navigation */
      return from.name ? next(false) : next({ name: views.HOMEPAGE })
    }

    // Unlock website config if navigating out of it
    if (!to.meta?.canBeLocked) await store.dispatch('websiteConfig/unlock')

    // Force default locale to en if route can't change locale
    if (to.meta?.cannotChangeLocale && i18n.global.locale.value !== config.DEFAULT_LANGUAGE) {
      loadLanguageAsync(config.DEFAULT_LANGUAGE)
      setAxiosHeader('Accept-Language', config.DEFAULT_LANGUAGE)
    }

    // Route protection with ACL
    return canNavigate(to) ? next() : next({ name: views.HOMEPAGE })
  }
  /** Unauthenticated user navigation will be confirmed if the route is public/public-only
   * or is the login route */
  if (to.meta?.public || to.meta?.publicOnly || to.name === views.LOGIN) return next()

  // Finally, unauthenticated user is redirect to login route
  return next({ name: views.LOGIN })
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
