import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import { passiveSupport } from 'passive-events-support/src/utils';
import { useRoute } from 'vue2-helpers/vue-router';
import { useState, useMutations } from '@store';
import { useI18n } from 'vue-i18n-bridge';
import { provideToast } from 'vue-toastification/composition';
import { computed, watch, provide } from '@vue/composition-api';
import { useWindowSize, useCssVar } from '@vueuse/core'; // This will be populated in `beforeCreate` hook

import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig';
import $enums from '@utils/enums';
import useAppConfig from '@core/app-config/useAppConfig'; // Making event listeners as passive

passiveSupport({
  events: ['wheel', 'mousewheel', 'touchstart', 'touchmove'],
  debug: false
});

var LayoutVertical = function LayoutVertical() {
  return import('@layouts/vertical/LayoutVertical.vue');
};

var LayoutHorizontal = function LayoutHorizontal() {
  return import('@layouts/horizontal/LayoutHorizontal.vue');
};

var LayoutFull = function LayoutFull() {
  return import('@layouts/full/LayoutFull.vue');
};

var setupStyles = function setupStyles() {
  // Set colors in theme
  var colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark'];

  for (var i = 0, len = colors.length; i < len; i++) {
    $themeColors[colors[i]] = useCssVar("--".concat(colors[i]), document.documentElement).value.trim();
  } // Set Theme Breakpoints


  var breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];

  for (var _i = 0, _len = breakpoints.length; _i < _len; _i++) {
    $themeBreakpoints[breakpoints[_i]] = Number(useCssVar("--breakpoint-".concat(breakpoints[_i]), document.documentElement).value.slice(0, -2));
  } // Set RTL


  var isRTL = $themeConfig.layout.isRTL;
  document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
};

export default {
  components: {
    LayoutHorizontal: LayoutHorizontal,
    LayoutVertical: LayoutVertical,
    LayoutFull: LayoutFull
  },
  setup: function setup() {
    setupStyles();
    var i18n = useI18n();
    var route = useRoute();
    provide('$enums', $enums);

    var _useAppConfig = useAppConfig(),
        skin = _useAppConfig.skin,
        skinClasses = _useAppConfig.skinClasses; // If skin is dark when initialized => Add class to body


    if (skin.value === $enums.config.DARK_LAYOUT) {
      document.body.classList.add('dark-layout');
    } // Provide toast for Composition API usage


    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
      maxToasts: 3
    }); // Set Window Width in store

    var _useMutations = useMutations('app', ['UPDATE_WINDOW_WIDTH']),
        updateWindowWidth = _useMutations.UPDATE_WINDOW_WIDTH;

    updateWindowWidth(window.innerWidth);

    var _useWindowSize = useWindowSize(),
        windowWidth = _useWindowSize.width;

    watch(windowWidth, function (val) {
      updateWindowWidth(val);
    });

    var _useState = useState('appConfig', ['layout']),
        layoutType = _useState.layout;

    var layout = computed(function () {
      var _route$meta, _route$meta2, _layoutType$value;

      // If the route is not yet resolved return layout-full
      if (!route.name) {
        return "layout-".concat($enums.config.LAYOUT_FULL);
      }

      return (_route$meta = route.meta) !== null && _route$meta !== void 0 && _route$meta.layout ? "layout-".concat((_route$meta2 = route.meta) === null || _route$meta2 === void 0 ? void 0 : _route$meta2.layout) : "layout-".concat((_layoutType$value = layoutType.value) === null || _layoutType$value === void 0 ? void 0 : _layoutType$value.type);
    });
    return {
      config: $enums.config,
      i18n: i18n,
      layout: layout,
      skinClasses: skinClasses
    };
  },
  // Match with vue-meta lib
  metaInfo: function metaInfo() {
    return {
      title: this.config.APP_NAME,
      htmlAttrs: {
        lang: this.i18n.locale.value
      }
    };
  }
};