import devices from '@enums/devices'

export default [
  {
    target: 'actirise',
    id: 'actirise',
    label: 'actirise',
    params: ['account', 'format'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [320, 480],
        [1800, 1000],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ],
      video: [
        [640, 480]
      ]
    }
  },
  {
    target: 'adf',
    id: 'adf',
    label: 'adf',
    params: ['mid'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ]
    }
  },
  {
    target: 'adf-outstream',
    id: 'adf-outstream',
    label: 'adf-outstream',
    params: ['mid'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      video: [[640, 480]]
    }
  },
  {
    target: 'adot',
    id: 'adot',
    label: 'adot',
    params: ['placementId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600]
      ]
    }
  },
  {
    target: 'adot',
    id: 'adot-320-480',
    label: 'adot-320-480',
    size_count: 1,
    params: ['placementId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [320, 480]
      ]
    }
  },
  {
    target: 'adot',
    id: 'adot-habillage',
    label: 'adot-habillage',
    size_count: 1,
    params: ['placementId'],
    devices: [devices.DESKTOP],
    sizes: {
      banner: [
        [1800, 1000]
      ]
    }
  },
  {
    target: 'adot-native',
    id: 'adot-native',
    label: 'adot-native',
    size_count: 1,
    params: ['placementId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      native: [
        [1, 1]
      ]
    }
  },
  {
    target: 'adot-outstream',
    id: 'adot-outstream',
    label: 'adot-outstream',
    params: ['placementId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      video: [
        [640, 480]
      ]
    }
  },
  {
    target: 'adsense',
    id: 'adsense',
    label: 'adsense',
    size_count: 1,
    params: [
      'data-ad-client',
      'data-ad-slot',
      'class',
      {
        name: 'size',
        type: Array
      }
    ],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    syncParamsSize: true
  },
  {
    target: 'adyoulike',
    id: 'adyoulike',
    label: 'adyoulike',
    params: ['placement'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ],
      video: [[640, 480]],
      native: [[1, 1]]
    }
  },
  {
    target: 'amazon',
    id: 'amazon',
    label: 'amazon',
    size_count: 1,
    params: [
      'pubId'
    ],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [970, 250],
        [970, 90],
        [728, 90],
        [336, 280],
        [320, 50],
        [320, 100],
        [300, 600],
        [300, 250],
        [300, 100],
        [300, 50],
        [160, 600],
        [120, 600]
      ],
      video: [
        [640, 480]
      ]
    }
  },
  {
    target: 'appnexus',
    id: 'appnexus',
    label: 'appnexus',
    params: ['placement_id'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [1800, 1000],
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ],
      video: [
        [640, 480]
      ],
      native: [[1, 1]]
    }
  },
  {
    target: 'bricks',
    id: 'bricks',
    label: 'bricks',
    params: ['account', 'pid', 'endpoint'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [320, 480],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600],
        [1800, 1000]
      ],
      video: [
        [640, 480]
      ]
    }
  },
  {
    target: 'criteo',
    id: 'criteo',
    label: 'criteo',
    params: ['networkId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [320, 480],
        [970, 250],
        [728, 90],
        [120, 600]
      ],
      video: [[640, 480]]
    }
  },
  {
    target: 'criteo-native',
    id: 'criteo-native',
    label: 'criteo-native',
    size_count: 1,
    params: ['networkId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      native: [
        [1, 1]
      ]
    }
  },
  {
    target: 'eplanning',
    id: 'eplanning',
    label: 'eplanning',
    params: ['ci'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [320, 480],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ],
      video: [[640, 480]]
    }
  },
  {
    target: 'freewheel-ssp',
    id: 'freewheel-ssp',
    label: 'freewheel-ssp',
    params: ['zoneId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      video: [
        [640, 480]
      ]
    }
  },
  {
    target: 'google',
    id: 'google',
    label: 'google',
    params: ['zone'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP]
  },
  {
    target: 'google_firstlook',
    id: 'google_firstlook',
    label: 'google_firstlook',
    params: ['zone'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP]
  },
  {
    target: 'google_unsold',
    id: 'google_unsold',
    label: 'google_unsold',
    params: ['zone'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP]
  },
  {
    target: 'grid',
    id: 'grid',
    label: 'grid',
    params: ['uid'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ]
    }
  },
  {
    target: 'grid-native',
    id: 'grid-native',
    label: 'grid-native',
    params: ['uid'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      native: [[1, 1]]
    }
  },
  {
    target: 'grid-outstream',
    id: 'grid-outstream',
    label: 'grid-outstream',
    params: ['uid'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      video: [
        [640, 480]
      ]
    }
  },
  {
    target: 'improvedigital',
    id: 'improvedigital',
    label: 'improvedigital',
    params: ['placementId', 'publisherId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ]
    }
  },
  {
    target: 'improvedigital',
    id: 'improvedigital-320-480',
    label: 'improvedigital-320-480',
    size_count: 1,
    params: ['placementId', 'publisherId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [320, 480]
      ]
    }
  },
  {
    target: 'improvedigital',
    id: 'improvedigital-habillage',
    label: 'improvedigital-habillage',
    params: ['placementId', 'publisherId'],
    devices: [devices.DESKTOP],
    sizes: {
      banner: [
        [1800, 1000]
      ]
    }
  },
  {
    target: 'improvedigital-outstream',
    id: 'improvedigital-outstream',
    label: 'improvedigital-outstream',
    params: ['placementId', 'publisherId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      video: [[640, 480]]
    }
  },
  // Exclude to skin desktop template
  {
    target: 'inskin',
    id: 'inskin',
    label: 'inskin',
    size_count: 1,
    params: ['siteId', 'networkId'],
    devices: [devices.DESKTOP],
    sizes: {
      banner: [
        [1, 1]
      ]
    }
  },
  {
    target: 'invibes',
    id: 'invibes',
    label: 'invibes',
    params: ['placementId'],
    slots: ['content'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP]
  },
  {
    target: 'invibes',
    id: 'invibes-desktop',
    label: 'invibes-desktop',
    size_count: 1,
    params: ['placementId'],
    slots: ['content'],
    devices: [devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250]
      ]
    }
  },
  {
    target: 'invibes',
    id: 'invibes-mobile',
    label: 'invibes-mobile',
    size_count: 1,
    params: ['placementId'],
    slots: ['content'],
    devices: [devices.MOBILE, devices.TABLET],
    sizes: {
      banner: [
        [300, 250]
      ]
    }
  },
  {
    target: 'ix',
    id: 'ix',
    label: 'ix',
    params: ['siteId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [320, 480],
        [1800, 1000],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ]
    }
  },
  {
    target: 'ix-native',
    id: 'ix-native',
    label: 'ix-native',
    params: ['siteId'],
    devices: [devices.DESKTOP, devices.MOBILE, devices.TABLET],
    sizes: {
      native: [
        [1, 1]
      ]
    }
  },
  {
    target: 'ix-outstream',
    id: 'ix-outstream',
    label: 'ix-outstream',
    size_count: 1,
    params: ['siteId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      video: [
        [640, 480]
      ]
    }
  },
  {
    target: 'missena',
    id: 'missena',
    label: 'missena',
    size_count: 1,
    params: ['websiteId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [[1, 1]]
    }
  },
  {
    target: 'missena-official',
    id: 'missena-official',
    label: 'missena-official',
    size_count: 1,
    params: ['apiKey'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [[1, 1]]
    }
  },
  {
    target: 'missenanative',
    id: 'missenanative',
    label: 'missenanative',
    size_count: 1,
    params: ['apiToken', 'networkId', 'zoneId', 'publisherSubId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      native: [[1, 1]]
    }
  },
  {
    target: 'oftmedia',
    id: 'oftmedia',
    label: 'oftmedia',
    params: ['placementId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ],
      video: [[640, 480]],
      native: [[1, 1]]
    }
  },
  {
    // Exclude to slideup template
    target: 'ogury',
    id: 'ogury',
    label: 'ogury',
    size_count: 1,
    params: [
      'adUnitId',
      'assetKey',
      'skipSizeCheck'
    ],
    devices: [devices.MOBILE],
    sizes: {
      banner: [
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100]
      ]
    }
  },
  {
    target: 'onetag',
    id: 'onetag',
    label: 'onetag',
    params: ['pubId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [1800, 1000],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ],
      video: [[640, 480]]
    }
  },
  {
    target: 'openx',
    id: 'openx',
    label: 'openx',
    params: ['delDomain', 'unit'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ],
      video: [[640, 480]]
    }
  },
  {
    target: 'outbrain',
    id: 'outbrain',
    label: 'outbrain',
    size_count: 1,
    params: ['widgetId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP]
  },
  {
    target: 'outbrain-official',
    id: 'outbrain-official',
    label: 'outbrain-official',
    params: ['tagid'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP]
  },
  {
    target: 'outbrain-smartfeed',
    id: 'outbrain-smartfeed',
    label: 'outbrain-smartfeed',
    size_count: 1,
    params: ['widgetId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [1, 1]
      ]
    }
  },
  {
    target: 'pubmatic',
    id: 'pubmatic',
    label: 'pubmatic',
    params: ['publisherId', 'adSlot'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [320, 480],
        [1800, 1000],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ]
    }
  },
  {
    target: 'pubmatic-native',
    id: 'pubmatic-native',
    label: 'pubmatic-native',
    params: ['publisherId', 'adSlot'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      native: [[1, 1]]
    }
  },
  {
    target: 'pubmatic-outstream',
    id: 'pubmatic-outstream',
    label: 'pubmatic-outstream',
    params: ['publisherId', 'adSlot'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      video: [[640, 480]]
    }
  },
  {
    target: 'rubicon',
    id: 'rubicon',
    label: 'rubicon',
    params: ['accountId', 'siteId', 'zoneId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [320, 480],
        [1800, 1000],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ],
      video: [[640, 480]],
      native: [[1, 1]]
    }
  },
  {
    target: 'sharethrough',
    id: 'sharethrough',
    label: 'sharethrough',
    size_count: 1,
    params: ['pkey'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP]
  },
  {
    target: 'smartadserver',
    id: 'smartadserver',
    label: 'smartadserver',
    params: ['networkId', 'siteId', 'pageId', 'formatId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [1800, 1000],
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 480],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ],
      video: [[640, 480]],
      native: [[1, 1]]
    }
  },
  {
    target: 'smilewanted',
    id: 'smilewanted',
    label: 'smilewanted',
    params: ['zoneId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ]
    }
  },
  {
    target: 'smilewanted-native',
    id: 'smilewanted-native',
    label: 'smilewanted-native',
    params: ['zoneId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      native: [
        [1, 1]
      ]
    }
  },
  {
    target: 'smilewanted-outstream',
    id: 'smilewanted-outstream',
    label: 'smilewanted-outstream',
    params: ['zoneId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      video: [
        [640, 480]
      ]
    }
  },
  {
    target: 'sparteo',
    id: 'sparteo',
    label: 'sparteo',
    params: ['networkId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [1, 1]
      ]
    }
  },
  {
    target: 'sparteo-slideshow',
    id: 'sparteo-slideshow',
    label: 'sparteo-slideshow',
    params: [
      'networkId',
      {
        name: 'formats',
        type: Array
      }
    ],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [320, 480],
        [1800, 1000],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ]
    }
  },
  // Exclude to skin desktop template
  {
    target: 'sublime',
    id: 'sublime',
    label: 'sublime',
    size_count: 1,
    params: ['zoneId'],
    devices: [devices.DESKTOP],
    sizes: {
      banner: [
        [1800, 1000]
      ]
    }
  },
  {
    target: 'teads',
    id: 'teads',
    label: 'teads',
    size_count: 1,
    params: ['pageId', 'placementId'],
    slots: ['content'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250]
      ]
    }
  },
  {
    target: 'teads-banner',
    id: 'teads-banner',
    label: 'teads-banner',
    params: ['pageId', 'placementId'],
    exclude: ['content'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ]
    }
  },
  {
    target: 'triplelift-native',
    id: 'triplelift-desktop-billboard',
    label: 'triplelift-desktop-billboard',
    size_count: 1,
    params: ['inventoryCode'],
    devices: [devices.DESKTOP],
    sizes: {
      banner: [
        [970, 250]
      ]
    },
    display970Conversion: true
  },
  {
    target: 'triplelift-native',
    id: 'triplelift-desktop-inarticle',
    label: 'triplelift-desktop-inarticle',
    size_count: 1,
    params: ['inventoryCode'],
    devices: [devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250]
      ]
    },
    display970Conversion: true
  },
  {
    target: 'triplelift-native',
    id: 'triplelift-desktop-rr',
    label: 'triplelift-desktop-rr',
    size_count: 1,
    params: ['inventoryCode'],
    devices: [devices.DESKTOP],
    sizes: {
      banner: [
        [300, 600]
      ]
    },
    display970Conversion: true
  },
  {
    target: 'triplelift-iab',
    id: 'triplelift-hdx',
    label: 'triplelift-hdx',
    params: ['inventoryCode'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ]
    }
  },
  {
    target: 'triplelift-native',
    id: 'triplelift-mobile-infeed',
    label: 'triplelift-mobile-infeed',
    size_count: 1,
    params: ['inventoryCode'],
    devices: [devices.MOBILE, devices.TABLET],
    sizes: {
      banner: [
        [300, 250]
      ]
    },
    display970Conversion: true
  },
  {
    target: 'unruly',
    id: 'unruly',
    label: 'unruly',
    params: ['siteId'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      banner: [
        [300, 250],
        [336, 280],
        [300, 600],
        [300, 50],
        [300, 100],
        [320, 50],
        [320, 100],
        [970, 250],
        [970, 90],
        [728, 90],
        [160, 600],
        [120, 600]
      ],
      video: [[640, 480]]
    }
  },
  {
    target: 'viously',
    id: 'viously',
    label: 'viously',
    params: ['pid'],
    devices: [devices.MOBILE, devices.TABLET, devices.DESKTOP],
    sizes: {
      video: [
        [640, 480]
      ]
    }
  }
]
